import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import 'lazysizes';

const swup = new Swup({
	plugins: [
    new SwupPreloadPlugin(),
		new SwupScrollPlugin({
			doScrollingRightAway: false,
			animateScroll: false
		})]
});


$(document).ready(function () {

  browserDetect()

  var mappingMixer = false;
  
  function createMix(){

    if (document.querySelector('#mapping')) {

      mappingMixer = mixitup("#mapping", {
        selectors: {
          target: '.mapping__item'
        },
        load: {
          sort: 'random'
        },
        animation: {
          duration: 300
        }
      });

    }
  }

  function destroyMix() {

    if(mappingMixer){
      mappingMixer.destroy();
      mappingMixer = false;
    }

  }

  if (document.querySelector('#mapping')) {
    createMix()
  }

	init();
	
	function init() {

    setVH()
    openLinksInNewTab()
    preLoad();
    projectSliceMeta()
    resizeSetHeight()
    watchForHover()
    addBodyClass()
    dominantColors()


    // HEADER

    toggleMenu();
    langMobileHidden();


    //mapping

    if (document.querySelector('#mapping')) {
      lazyload()
      mappingImgHover();
      mappingImgClick();
      mappingSortMix();
    }


    //PROJECT

    projectToggleLayers()
    projectSlideshowSlick()
    projectReadMore() 
    projectDeleteZoomImg() 


    //GENERAL

    generalMinHeight()


  // window.addEventListener('resize', () => {
  //   let vh = window.innerHeight * 0.01;
  //   document.documentElement.style.setProperty('--vh', `${vh}px`);
  // });

  }

  swup.on('contentReplaced', init);
  swup.on('pageView', init);
  swup.on('willReplaceContent', destroyMix);
  swup.on('contentReplaced', createMix);
  swup.on('contentReplaced', dominantColors);
  swup.on('contentReplaced', resizeSetHeight);



  var resizeTimer;
      
  $(window).on("resize scroll touchstart", function () {
    setVH() 
  });

  $(window).resize(function () {
        
    // setVH()
    wbrPrivacy Policy();
    langMobileHidden();
    projectSliceMeta()
    projectSetMetaHeight()
    
    clearTimeout(resizeTimer);
    
    resizeTimer = setTimeout(function () {
      
      resizeSetHeight()
      projectSliceMeta()
      generalMinHeight()
        
    }, 200);
        
  })

});